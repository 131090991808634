/* Using Material-UI CssBaseline
 - See https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/CssBaseline/CssBaseline.js
*/

body {
  background-color: white;
}
body::-webkit-scrollbar {
  display: none;
}
/* 
#root > div + div {
  background-color: #f5f5f5;
} */

.form {
  width: 100%; /* Fix IE 11 issue. */
  margin-top: 1px;
}

.inblock {
  display: inline-block;
}

/* Material-UI Overrides
  - Alternatively, you can override things in your theme JS.
*/

.MuiFormControlLabel-label {
  user-select: none;
}

.MuiInputLabel-animated {
  user-select: none;
}

.MuiInputLabel-formControl {
  user-select: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757575;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #757575;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #757575;
}

[id^="checkboxes-tags-demo-option"] {
  color: #03363d;
  font-weight: 400;
  font-size: 14px;
  padding: 0 !important;
}

[id^="checkboxes-tags-demo-option"]:hover {
  background: rgba(150, 204, 212, 0.15);
}

.MuiAutocomplete-paper {
  border-radius: 2px !important;
  background-color: #fafafa !important;
  box-shadow: 0 1px 1px 1px #33333326 !important;
}

.MuiAutocomplete-popper .MuiButtonBase-root-MuiCheckbox-root {
  padding: 7px 8px 8px;
  margin: 0 !important;
}

[id^="checkboxes-tags-demo-option"] .MuiButtonBase-root-MuiCheckbox-root svg {
  color: #b5b5b5;
}

[id^="checkboxes-tags-demo-option"]
  .MuiButtonBase-root-MuiCheckbox-root.Mui-checked
  svg {
  color: #03363d;
}

#checkboxes-tags-demo-listbox {
  max-height: 214px;
  padding: 0;
}

#checkboxes-tags-demo-listbox::-webkit-scrollbar {
  width: 2px;
}

#checkboxes-tags-demo-listbox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(111, 192, 213, 0.1);
  border-radius: 10px;
}

#checkboxes-tags-demo-listbox::-webkit-scrollbar-thumb {
  background: #c9cccf;
  border-radius: 10px;
}

.MuiAutocomplete-root .MuiAutocomplete-tag {
  height: 26px;
  margin: 8px 8px 0 0 !important;
  font-size: 12px;
  color: #03363d;
  border-radius: 4px;
  background-color: rgba(150, 204, 212, 0.15);
}

.MuiAutocomplete-root .MuiAutocomplete-tag span {
  padding: 0 8px;
  line-height: 26px;
}

.MuiAutocomplete-root .MuiAutocomplete-tag svg {
  margin: 0 6px 0 0;
}

.MuiPaper-root.lending-profile {
  padding: 20px 30px 18px 20px;
}

h3:empty {
  display: none;
}

.lending-profile h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #757575;
  margin-bottom: 4px;
}

.lending-profile h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  color: #03363d;
  text-transform: uppercase;
}

.lending-profile h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #03363d;
  text-transform: uppercase;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper {
  max-height: 30vh !important;
  /* stylelint-disable */
  scrollbar-width: thin;
  scrollbar-color: #878787 transparent;
  border-radius: 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper::-webkit-scrollbar {
  width: 2px;
  height: 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper::-webkit-scrollbar-thumb {
  background-color: #878787;
  border-radius: 0;
  border: 1px solid transparent;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper::-webkit-scrollbar-track {
  background-color: transparent;
}
